/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Content = styled(ParseContent)`
  padding-right: 60px;

  & h2 {
    padding-bottom: 20px;
    font-weight: ${(props) => props.theme.font.size[32]};
  }

  @media screen and (max-width: 991px) {
    padding-right: 0;
  }
`

const StyledCol = styled(motion.div)`
  padding: 25px 25px 25px 25px;

  @media screen and (max-width: 1200px) {
    width: 194px;
  }

  @media screen and (max-width: 991px) {
    width: initial;
  }

  @media screen and (max-width: 768px) {
    width: unset;
    padding: 15px 15px 45px 15px !important;
  }
`

const Round = styled(motion.div)`
  height: 15px;
  width: 15px;
  border: 3px solid ${(props) => props.theme.color.face.contrast};
  border-radius: 8px;
  margin: 0 10px 0 0;
`

const Title = styled.div`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.font.weight.xl};
  font-size: ${(props) => props.theme.font.size[20]};
  line-height: 20px;
  color: ${(props) => props.theme.color.text.main};

  &:hover {
    color: ${(props) => props.theme.color.text.main};
  }
`

const StyledImage = styled(Plaatjie)`
  object-fit: contain;
  height: 100%;
  position: absolute !important;
  width: 100%;
  border-radius: 15px;

  & img {
    border-radius: 15px;
  }
`

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 185px;
  border-radius: 30px;

  @media screen and (max-width: 768px) {
    min-height: 130px;
  }
`

interface TextWithApplicationsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithApplications
}

const TextWithApplications: React.FC<TextWithApplicationsProps> = ({
  fields,
}) => (
  <div className="container py-lg-5 py-3">
    <div className="row">
      <div className="col-lg-6 d-flex align-items-center">
        <Content content={fields.description} />
      </div>
      <div className="col-lg-6 pt-lg-0 pt-5">
        <div className="row">
          {fields?.applications?.map((application, index: number) => (
            <StyledCol
              initial="init"
              whileHover="hover"
              className="mb-3 col-6 col-lg-6"
              key={index}
            >
              <Link to={application?.link?.url || '/404'}>
                <Wrapper
                  className="position-relative"
                  initial={{ borderRadius: '8px' }}
                  variants={{
                    hover: { boxShadow: '0 0 10px rgba(0,0,0,0.3)' },
                  }}
                >
                  <StyledImage image={application?.image} alt="" />
                </Wrapper>
                <div className="pt-4 text-center d-flex justify-content-center align-items-center">
                  <Round
                    variants={{
                      init: { y: 0 },
                      hover: {
                        y: [0, -10, 0, -10, 0],
                        transition: { repeat: Infinity, duration: 2 },
                      },
                    }}
                  />
                  <Title>{application?.link?.title}</Title>
                </div>
              </Link>
            </StyledCol>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default TextWithApplications
